.polityka-prywatnosci{
    width: 1116px;
    h1{
        margin-top: 20px;
    }
    h2{
        margin-top: 20px;
    }
    p{
        margin-top: 20px;
    }

    ul{
        padding-left: 40px;
    }
}

@media (max-width: 1116px){
    .polityka-prywatnosci{
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 767px){
    .polityka-prywatnosci{
        padding-left: 20px;
        padding-right: 20px;

        h1{
            font-size: 30px;
        }
        h2{
            font-size: 20px;
        }
        p{
            font-size: 14px;
        }
    }
    
}