.kontakt {
    width: 1116px;
    margin-top: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 32px;
        font-weight: 700;
    }

    .kontakt-grid {
        display: grid;
        grid-template-columns: 1fr 75px 1fr;
        margin-top: 27px;

        .kontakt-left {
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 32px;
                font-weight: 900;
            }

            p {
                font-size: 20px;
                line-height: 25px;
                font-weight: 400;
            }

            h4 {
                font-size: 24px;
                font-weight: 900px;
                margin-top: 24px;
            }

            h5 {
                font-size: 20px;
                font-weight: 900;
            }

            a {
                text-decoration: none;
                color: #000000;
                transition: all 500ms;

                &:hover {
                    color: #0A58CA;
                }
            }

            .first-paragraph {
                margin-top: 23px;
            }

            .second-paragraph {
                margin-top: 15px;
            }

            .call-me {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 9px;
            }

            .mail-me{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 9px;
            }

            .mapa {
                margin-top: 29px
            }
        }

        .kontakt-middle {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .kontakt-right {
            .contact-form {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 24px;

                input,
                textarea {
                    padding: 16px 31px 16px 31px;
                    border-radius: 46px;
                    border: 1px solid #989797;
                    font-size: 18px;
                    font-weight: 400;
                    color: #6F6F6F;
                }

                textarea{
                    min-height: 153px;
                }

                .consent{
                    margin-top: 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    column-gap: 26px;

                    input{
                        width: 24px;
                        height: 24px;
                    }

                    label{
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 20px;
                        color: #000000;
                    }
                }

                .submit-btn{
                    width: 261px;
                    height: 48px;
                    border: none;
                    border-radius: 24px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background-color: #DC3545;
                    transition: all 500ms;
                    font-size: 20px;
                    font-weight: 700;
                    cursor: pointer;
                    column-gap: 7px;
                    color: #ffffff;
                    margin-top: 25px;

                    img{
                        width: 30px;
                        height: 30px;
                        margin-top: -5px;
                    }

                    &:hover{
                        background-color: #0A58CA;
                        transform: scale(1.05);
                    }
                }


            }

        }
    }
}

@media (max-width: 1115px){
    .kontakt{
        width: 100%;
        .kontakt-grid{
            grid-template-columns: 100%;
            padding-left: 40px;
            padding-right: 40px;

            .kontakt-middle{
                display: none;
            }

            .kontakt-right{
                margin-top: 40px;
            }
        }
    }
}

@media (max-width: 768px){
    .kontakt{
        margin-top: 70px;
        h2{
            font-size: 24px;
        }

        .kontakt-grid{
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;

            .kontakt-left{
                h3{
                    font-size: 20px;
                }

                .first-paragraph{
                    margin-top: 15px;
                }

                p{
                    font-size: 14px;
                    line-height: 19px;
                }

                h5{
                    font-size: 16px;
                }

                h4{
                    font-size: 20px;
                }
            }

            .kontakt-right{
    
                .contact-form{
                    gap: 15px;
                    
    
                    input,
                    textarea{
                        font-size: 14px;
                        padding: 14px 20px 14px 20px;
                    }

                    .consent{

                        label{
                           font-size: 14px;
                           line-height: 19px; 
                        }
                    }
                }
            }
        }

    }
}