.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 122px;

    .footer-blue {
        background-color: #0A58CA;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 123px;

        .footer-blue-content {
            width: 1116px;
            display: flex;
            flex-direction: row;
            column-gap: 125px;

            .footer-menu {
                display: flex;
                align-items: center;

                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: row;
                    column-gap: 57px;

                    .footer-menu-link {
                        font-size: 16px;
                        font-weight: 700;
                        color: #ffffff;
                        cursor: pointer;
                        transition: all 500ms;
                        text-decoration: none;

                        &:hover {
                            color: #000000;
                        }

                    }
                }
            }
        }
    }

    .footer-white {
        min-height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;

        .footer-white-content {
            width: 1116px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h6 {
                font-size: 14px;
                font-weight: 400;
            }

            a {
                color: #000000;
            }
        }


    }
}

@media (max-width: 1115px) {
    .footer .footer-blue .footer-blue-content {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }

    .footer .footer-white .footer-white-content {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

        h6{
            font-size: 10px;
        }
    }
}

@media (max-width: 768px){
    .footer{
        margin-top: 70px;
        .footer-blue{

            .footer-blue-content{
                padding-left: 20px;
                padding-right: 20px;
                column-gap: 0px;
                justify-content: space-between;
                align-items: center;


                img{
                    width: 120px;
                    height: 42px;
                }

                .footer-menu{

                    ul{
                      flex-direction: column;
                      align-items: flex-end;
                      justify-content: center;  

                      .footer-menu-link{
                        font-size: 14px;
                      }
                    }
                }
            }
        }

        .footer-white{
            .footer-white-content{
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 5px;
                padding-bottom: 5px;
                flex-direction: column;
                row-gap: 5px;

                h6{
                    text-align: center;
                }
            }
        }
    }
}