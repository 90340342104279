.rzeczoznawcy-hero {
    width: 1116px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .hero-left {
        width: 50%;

        .left-top {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            column-gap: 7px;
            margin-top: 112px;

            img {
                margin-top: 12px;
            }

            h1 {
                font-size: 40px;
                font-weight: 900;
                line-height: 50px;
            }
        }

        .left-bottom {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            p {
                font-size: 20px;
                line-height: 25px;
                font-weight: 400;
            }

            h3{
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
                margin-top: 12px;   
            }

            button{
                width: 173px;
                height: 48px;
                border-radius: 24px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                column-gap: 13px;
                background-color: #DC3545;
                color: #ffffff;
                margin-top: 31px;
                border: none;
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
                cursor: pointer;
                transition: all 500ms;

                &:hover{
                    background-color: #0A58CA;
                    transform: scale(1.05)
                }

            }


        }
    }

    .hero-right {
        width: 50%;
        min-height: 454px;
        background-image: url('../Assets/infografika.avif');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

    }

    .contact-link{
        text-decoration: none;
    }

}

@media (max-width: 1115px){
    .rzeczoznawcy-hero{
        width: 100%;
        flex-direction: column;
        padding-left: 40px;
        padding-right: 40px;

        .hero-left{
            width: 100%;
            .left-top{
                margin-top: 40px;
            }
        }

        .hero-right{
            width: 100%;
        }
    }
}

@media (max-width: 768px){
    .rzeczoznawcy-hero{
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column-reverse;

        .hero-left{

            .left-top{
                margin-top: 20px;

                img{
                    width: 24px;
                    margin-top: 7px
                }

                h1{
                    font-size: 28px;
                    line-height: 38px;
                }
            }

            .left-bottom{
                p{
                    font-size: 16px;
                    line-height: 21px;
                }

                button{
                    margin-top: 20px;
                }

            }
        }

        .hero-right{
            min-height: 263px;
        }
    }
}