.oferta {
    width: 1116px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 72px;

    h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .oferta-box{
        width: 734px;
        height: 155px;
        border: 1px solid #E5E5E5;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 24px;
        padding-left: 71px;
        border-radius: 50px;
        margin-top: 12px;

        p{
           font-size: 20px;
           line-height: 25px;
           font-weight: 400; 
        }

        &:nth-child(3){
            background-color: #FAF9F9;
            border: 1px solid #FAF9F9;
        }
    }


}

@media (max-width: 1115px){
    .oferta{
        width: 100%;
    }
}

@media (max-width: 768px){
    .oferta{
        padding-left: 20px;
        padding-right: 20px;

        h2{
            font-size: 24px;
            text-align: center;
        }

        .oferta-box{
            width: 100%;
            padding-left: 24px;
            padding-right: 24px;

            img{
                height: 60px;
            }

            p{
                font-size: 14px;
                line-height: 18px;

                br{
                    display: none;
                }
            }
        }

    }
}