@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');


.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Mulish', sans-serif;

  .App-header{
    min-height: 100px;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .App-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .App-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px){
  .App .App-header{
    min-height: 80px;
  }
}
