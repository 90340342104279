.wyksztalcenie {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 122px;


    h2 {
        font-size: 32px;
        font-weight: 700;
    }

    .desc-bg {
        margin-top: 27px;
        width: 100%;
        min-height: 420px;
        background-image: url('../Assets/wyksztalcenie.avif');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .desc {
            width: 1116px;
            color: #ffffff;

            p {
                font-size: 20px;
                line-height: 25px;
                font-weight: 400;
            }

            hr{
                width: 196px;
                height: 0px;
                border: 2px solid #ffffff;
                margin-top: 18px;
            }

            h3{
                margin-top: 17px;
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
            }


        }
    }
}

@media (max-width: 1115px){
    .wyksztalcenie .desc-bg .desc {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 768px){
    .wyksztalcenie{
        margin-top: 70px;

        h2{
            font-size: 24px;
            text-align: center;
        }

        .desc-bg{
            background-position: right center;
            min-height: 400px;
            .desc{
                padding-left: 20px;
                padding-right: 20px;
                p{
                    font-size: 14px;
                    line-height: 19px;
                    br{
                        &:nth-child(1),
                        &:nth-child(3),
                        &:nth-child(4){
                            display: none;
                        }
                    }
                }

                hr{
                    border: 1px solid #ffffff;
                }

                h3{
                    font-size: 14px;
                    line-height: 19px;;
                }


            }
        }

    }

}