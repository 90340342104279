.Header-container{
    width: 1116px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    column-gap: 125px;
    align-items: center;

    .main-menu{
        ul{
            list-style: none;
            display: flex;
            flex-direction: row;
            column-gap: 57px;
            li{
                font-size: 16px;
                font-weight: 700;
                .menu-link{
                    color: #000000;
                    text-decoration: none;
                    transition: all 500ms;

                    &:hover{
                        color: #0A58CA;
                    }
                }
            }
        }
    }
}

@media (max-width: 1115px){
    .Header-container{
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 768px){
    .Header-container{
        padding-left: 20px;
        padding-right: 20px;
        column-gap: 0px;
        justify-content: space-between;
        margin-top: 20px;

        img{
            width: 120px;
        }

        .main-menu{
            ul{
                flex-direction: column;
                row-gap: 5px;
                align-items: center;
                justify-content: center;
                li{
                    font-size: 14px;
                }
            }
        }
    }

}